.rays {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    margin: auto;

    /* background: linear-gradient(
        90deg,
        transparent,
        transparent,
        transparent,
        #fff8,
        transparent,
        transparent,
        transparent
    ); */
    background: conic-gradient(
        #fff2 0deg 5deg,
        #fff0 10deg 90deg,
        #ff83 95deg 100deg,
        #fff0 105deg 180deg,
        #fff3 185deg 190deg,
        #fff0 195deg 270deg,
        #ff82 275deg 280deg,
        #fff0 285deg 360deg
    );
    mix-blend-mode: plus-lighter;
    animation: rotate 20s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) scale(1);
        /* border-color: red; */
    }
    25% {
        transform: rotate(90deg) scale(4);
        /* border-color: yellow; */
    }
    50% {
        transform: rotate(180deg) scale(5);
        /* border-color: green; */
    }
    75% {
        transform: rotate(270deg) scale(4);
        /* border-color: blue; */
    }
    100% {
        transform: rotate(360deg) scale(1);
        /* border-color: purple; */
    }
}
