@import url("https://fonts.googleapis.com/css2?family=Eagle+Lake&display=swap");
body {
    font-family: "Comic Neue", "quick sand";
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: radial-gradient(
            circle,
            var(--color-back),
            var(--color-back-dark),
            var(--color-back),
            var(--color-back-dark)
        ); */

    background-color: #ccc;
    color: var(--color-text-normal);
    user-select: none; /* Disable text selection */
    overflow-x: clip; /* Prevent some strange horizontal scrollbars */
}

/* .close {
    position: fixed;
    font-size: 36px;
    font-weight: bold;
    color: #777;
    padding: 5px 10px 5px 10px;
    transform: scaleX(1.2);
    height: 40px; 
    border-radius: 50%;
    border: 2px solid #777;
}
.close::before {
    content: "X";
}
.close:hover {
    color: red;
}
.back {
    position: absolute;
    line-height: 0.5;
    font-size: 100px;
    font-weight: bold;
    color: #777;
    height: 40px;
    padding: 0;
    margin: 0;
}
.back::before {
    content: " ←";
}
.back:hover {
    color: #0ff;
} */

.btn {
    display: block;
    position: relative;
    width: 200px;
    padding: 10px 20px;
    border-radius: 999px;
    background: #f808;
    color: #fff;
    transition: all 0.2s ease;
}

.bigButton {
    background: none;
    color: #f80;
    width: 120px;
    aspect-ratio: 1/1;
    border: 4px solid #a60;
    border-radius: 999px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    filter: drop-shadow(0 1px 2px #000);
    backdrop-filter: blur(10px);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 99;
}

.bigButton:hover {
    transform: scale(1.1);
    background: radial-gradient(circle, #5ff, #044);
    color: white;
    border: 4px solid #fff;
    box-shadow: 0 0 50px #0ff;
}
.bigButton:active {
    transform: rotate(-36deg) scale(0.8);
}
/* -------------------- Interesting effect classes ------------------------*/
.PatternedGlass {
    background-color: transparent;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1));
    background-size: 20px 20px;
}
.rolled {
    background-color: #000;
    position: relative;
}
.rolled:before,
.rolled:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 20px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #000;
    box-shadow: 0 20px 10px #000;
    transform: rotate(-6deg);
}

.rolled:after {
    transform: rotate(6deg);
    right: 10px;
    left: auto;
}
/* -------------------------------------------*/
@property --a {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}
.vivid {
    overflow: hidden;
    position: relative;
    /* width: min(12.5em, 80vmin);
        aspect-ratio: 1; */
    /* border-radius: 0.5em; */
    /* text & layout styles below just for prettifying */
    place-self: center;
    place-content: center;
    /* padding: 0.5em; */
    /* color: #ededed; */
    /* font: clamp(1em, 2vw + 2vh, 2em) sans-serif; */
    text-align: center;
    /* text-transform: uppercase; */
    text-wrap: balance;
}

.vivid::before {
    position: absolute;
    z-index: -1;
    inset: -1em;
    border: solid 1.25em;
    border-image: conic-gradient(from var(--a), #690, #9c3, #ce6, #069, #39c, #906, #c39, #f60, #f90, #fc0, #690) 1;
    /* blur this pseudo */
    filter: blur(0.75em);
    animation: a 4s linear infinite;
    /* needed so pseudo is displayed */
    content: "";
}

/* animate --a from its initial-value 0deg to 1turn */
@keyframes a {
    to {
        --a: 1turn;
    }
}
/* -------------------- Useful animated classes ------------------------*/
.autoRotate {
    animation: rotateFullAnimation;
    animation-timeline: view();
}

@keyframes rotateFullAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* -------------------------------------------*/
.autoShow {
    animation: fadeInAnimation both;
    animation-timeline: view(70% 5%);
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(200px) scale(0.3);
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
/* -------------------------------------------*/
.autoBlur {
    animation: blurAnimation linear both;
    animation-timeline: view();
}
@keyframes blurAnimation {
    0% {
        filter: blur(40px);
    }
    45%,
    55% {
        filter: blur(0px);
    }
    100% {
        filter: blur(40px);
    }
}
/*-------------------------------------------*/

.autoFadeOut {
    animation: fadeOutAnim linear;
    animation-timeline: view();
    animation-range: exit;
}
.autoFadeIn {
    animation: fadeInAnim linear;
    animation-timeline: view();
    animation-range: entry;
}

/*-------- Amazing Hover effect ------------------*/
.container:hover .square:not(:hover) {
    filter: grayscale(1) blur(2px);
}
/* ----------------------------------------------- */
.scroll-watcher {
    height: 10px;
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #00f;
    width: 100%;
    scale: 0 1;
    transform-origin: left;
    animation: scrollAnimation linear;
    animation-timeline: scroll();
}
@keyframes scrollAnimation {
    to {
        scale: 1 1;
    }
}

/*-------- keyframes for animations ------------------*/

@keyframes fadeInAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOutAnim {
    from {
        /* transform: scale(1); */
        opacity: 1;
    }
    to {
        transform: scale(0.5);
        opacity: 0;
    }
}
/* ----------------------------------------------- */
.shake {
    animation: shake 0.4s ease;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}

/* ---------------------- Atomic Classes  ------------------------- */
.cBrand1 {
    color: #549;
}
.cBrand2 {
    color: #203;
}
.cBrand3 {
    color: var(--color-brand-alt);
}
.cWhite {
    color: #fff;
}
.cBlack {
    color: #000;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.flex {
    display: flex;
}
.center {
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
    margin: 10px auto;
}
.full-w {
    width: 100%;
}
.full-h {
    height: 100%;
}
.blur {
    backdrop-filter: blur(10px);
}
.clickable {
    cursor: pointer;
}
.clickable:hover {
    filter: brightness(1.2);
}
.clickable:active {
    filter: brightness(0.8);
}
