.container {
    position: fixed;
    inset: 0;
    text-align: center;
    font-family: system-ui, sans-serif;
    background: url("../public/universe-3.webp") center/cover fixed;
}

.intro {
    font-family: "Eagle Lake", sans-serif;
    width: 80%;
    margin: 0 auto;
    font-size: clamp(18px, 1.5vw, 32px);
    line-height: 1.4;
    color: #044;
    text-shadow: 0 0 5px #fff;
    background-color: #fff2;
    border: 5px solid #fff3;
    border-radius: 20px;
    backdrop-filter: blur(10px);
}
.intro p {
    margin: 2vw clamp(20px, 4vw, 80px);
}
.word {
    font-size: clamp(3rem, 15vw, 8rem);
    font-weight: bold;
    color: #335;
    margin: 20vh auto 0;
    text-shadow: 0 0 50px #fff;
    filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 20px #fff);
    animation: fadeIn 3s forwards;
}
.definition {
    font-size: clamp(1.2rem, 2vw, 3rem);
    margin-top: 0;
}
.word-box {
    animation: fadeIn 2s forwards;
}

.showBtn {
    display: inline-block;
    padding: 1.5vw;
    background-color: #aaa1;
    background-image: linear-gradient(90deg, #fff, #fff8, #aaa1, #fff8, #fff);
    border-color: #fff3;
    width: clamp(120px, 20vw, 20vw);
    font-size: clamp(24px, 1.5vw, 60px);
    color: #08a;
    margin: 1vw;
    border-radius: 30px;
    filter: drop-shadow(0 5px 8px #0003);
    transition: filter 0.5s;
    cursor: pointer;
}
.showBtn:hover {
    filter: drop-shadow(0 15px 15px #0ff3) brightness(1.1);
}

.rayContainer {
    display: block;
    position: absolute;
    animation: fadeIn 2s ease-out;
    width: 100%;
    height: 100%;
}
.ray {
    top: -5vh;
    left: 0;
}
.expiry-text {
    font-style: italic;
    margin-top: 1rem;
    opacity: 0.9;
}
.fadeOut {
    animation: fadeOut 2s forwards;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeInX {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(4);
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* mobile screen */
@media only screen and (max-width: 600px) {
    .word {
        margin-top: 30vh;
    }
    .showBtn {
        padding: 4vw;
        width: 50vw;
    }
}
